import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { Deco } from '@/features/common/'
import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { GoFurtherLink } from './GoFurtherLink'

interface Props extends ComponentPropsWithoutRef<'section'> {
  data?: Queries.GoFurtherSectionFragment | null
}

export const GoFurtherSection = ({
  data,
  ...props
}: Props): JSX.Element => {
  const styles = {
    section: css`
      background: #000;
      padding: var(--row-108) var(--margin);
      color: #fff;
      text-align: center;
      display: grid;
      justify-items: center;
    `,
    heading: css`
      font-size: var(--fs-60);
      text-align: center;
      margin: 0 0 0.5em;
      text-transform: uppercase;
    `,
    links: css`
      margin-top: 3em;
      width: 100%;
      display: grid;
      align-items: stretch;
      grid-template-columns: repeat(${data?.links?.length}, 1fr);
      gap: 0.5rem;
      ${mq().s} {
        grid-template-columns: 1fr;
      }
    `,
    link: css`
      min-height: ${(data?.links?.length || 0) > 2 ? '5em' : '6em'};
      ${mq().s} {
        min-height: 5em;
      }
    `,
  }
  return (
    <section
      css={styles.section}
      {...props}
    >
      <h2 css={styles.heading}>{data?.heading}</h2>
      <Deco fill={colors.redLight} />
      <div css={styles.links}>
        {data?.links?.map((link, i) => (
          <GoFurtherLink
            css={styles.link}
            data={link}
            key={i}
          />
        ))}
      </div>
    </section>
  )
}

export const GoFurtherSectionFragment = graphql`
  fragment GoFurtherSection on DatoCmsGoFurtherSection {
    __typename
    id: originalId
    heading
    links {
      ... on DatoCmsDivisionPage {
        __typename
        slug
        heading: divisionName
        images: heroImages {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsMissionPage {
        __typename
        slug
        heading: pageHeading
        images {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsStoriesPage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsTourPage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsNewsPage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsWelcomePage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsAthleticsWelcomePage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsAthleticFacilitiesPage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsAthleticsTeamsSchedulesPage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsNewsletterPage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsAthleticsAwardsPage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsInteriorPage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsOurPeoplePage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsDirectoryPage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsGivingPage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsDivisionAdmissionsPage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsAdmissionsWelcomePage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsAdmissionsEventsPage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsAdmissionsFaqPage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsTuitionPage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsStrategicVisionPage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsAlumnaeArticlesPage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsAlumnaeAssociationPage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsAlumnaeAwardsPage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsAlumnaeEventsPage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsAlumnaeDirectoryPage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsContactAndDirectionsPage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsHistoryPage {
        __typename
        slug
        heading: pageHeading
        historySections {
          images {
            ...GoFurtherThumbnail
          }
        }
      }
      ... on DatoCmsStatsPage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
      ... on DatoCmsAcademicCalendarPage {
        __typename
        slug
        heading: pageHeading
        image: heroImage {
          ...GoFurtherThumbnail
        }
      }
    }
  }
`
